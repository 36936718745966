<template>
  <div>
    <b-table-simple
      hover
      caption-top
      caption-bottom
      responsive
    >
      <b-thead>
        <b-tr>
          <b-th
            class="text-nowrap align-middle"
            rowspan="2"
          >
            TEMAS TİPİ
          </b-th>
          <b-th
            class="text-center"
            colspan="2"
          >
            OCAK
          </b-th>
          <b-th
            class="text-center"
            colspan="2"
          >
            ŞUBAT
          </b-th>
          <b-th
            class="text-center"
            colspan="2"
          >
            MART
          </b-th>
          <b-th
            class="text-center"
            colspan="2"
          >
            1. Çeyrek
          </b-th>
          <b-th
            class="text-center"
            colspan="2"
          >
            NİSAN
          </b-th>
          <b-th
            class="text-center"
            colspan="2"
          >
            MAYIS
          </b-th>
          <b-th
            class="text-center"
            colspan="2"
          >
            HAZİRAN
          </b-th>
          <b-th
            class="text-center"
            colspan="2"
          >
            2. Çeyrek
          </b-th>
          <b-th
            class="text-center"
            colspan="2"
          >
            TEMMUZ
          </b-th>
          <b-th
            class="text-center"
            colspan="2"
          >
            AĞUSTOS
          </b-th>
          <b-th
            class="text-center"
            colspan="2"
          >
            EYLÜL
          </b-th>
          <b-th
            class="text-center"
            colspan="2"
          >
            3. Çeyrek
          </b-th>
          <b-th
            class="text-center"
            colspan="2"
          >
            EKİM
          </b-th>
          <b-th
            class="text-center"
            colspan="2"
          >
            KASIM
          </b-th>
          <b-th
            class="text-center"
            colspan="2"
          >
            ARALIK
          </b-th>
          <b-th
            class="text-center"
            colspan="2"
          >
            4. Çeyrek
          </b-th>
          <b-th
            rowspan="2"
            class="text-center text-nowrap align-middle"
          >
            Toplam Görüşme
          </b-th>
          <b-th
            rowspan="2"
            class="text-center text-nowrap align-middle"
          >
            Toplam Satış
          </b-th>
          <b-th
            rowspan="2"
            class="text-center text-nowrap align-middle"
          >
            Satış Kapama
          </b-th>
        </b-tr>
        <b-tr>
          <b-th class="text-center font-small-1">
            Görüşme
          </b-th>
          <b-th class="text-center font-small-1">
            Satış
          </b-th>
          <b-th class="text-center font-small-1">
            Görüşme
          </b-th>
          <b-th class="text-center font-small-1">
            Satış
          </b-th>
          <b-th class="text-center font-small-1">
            Görüşme
          </b-th>
          <b-th class="text-center font-small-1">
            Satış
          </b-th>
          <b-th class="text-center font-small-1">
            Görüşme
          </b-th>
          <b-th class="text-center font-small-1">
            Satış
          </b-th>
          <b-th class="text-center font-small-1">
            Görüşme
          </b-th>
          <b-th class="text-center font-small-1">
            Satış
          </b-th>
          <b-th class="text-center font-small-1">
            Görüşme
          </b-th>
          <b-th class="text-center font-small-1">
            Satış
          </b-th>
          <b-th class="text-center font-small-1">
            Görüşme
          </b-th>
          <b-th class="text-center font-small-1">
            Satış
          </b-th>
          <b-th class="text-center font-small-1">
            Görüşme
          </b-th>
          <b-th class="text-center font-small-1">
            Satış
          </b-th>
          <b-th class="text-center font-small-1">
            Görüşme
          </b-th>
          <b-th class="text-center font-small-1">
            Satış
          </b-th>
          <b-th class="text-center font-small-1">
            Görüşme
          </b-th>
          <b-th class="text-center font-small-1">
            Satış
          </b-th>
          <b-th class="text-center font-small-1">
            Görüşme
          </b-th>
          <b-th class="text-center font-small-1">
            Satış
          </b-th>
          <b-th class="text-center font-small-1">
            Görüşme
          </b-th>
          <b-th class="text-center font-small-1">
            Satış
          </b-th>
          <b-th class="text-center font-small-1">
            Görüşme
          </b-th>
          <b-th class="text-center font-small-1">
            Satış
          </b-th>
          <b-th class="text-center font-small-1">
            Görüşme
          </b-th>
          <b-th class="text-center font-small-1">
            Satış
          </b-th>
          <b-th class="text-center font-small-1">
            Görüşme
          </b-th>
          <b-th class="text-center font-small-1">
            Satış
          </b-th>
          <b-th class="text-center font-small-1">
            Görüşme
          </b-th>
          <b-th class="text-center font-small-1">
            Satış
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="(row,key) in report.data"
          :key="key"
        >
          <b-th class="text-nowrap">
            {{ row.title }}
          </b-th>
          <template
            v-for="(item,iKey) in row.data"
          >
            <b-td
              :key="'int_' + iKey"
              class="text-center"
            >
              {{ item.interviews }}
            </b-td>
            <b-td
              :key="'sale_' + iKey"
              class="text-center"
            >
              {{ item.sales }}
            </b-td>
          </template>
          <b-td class="text-center">
            {{ row.interviews_total }}
          </b-td>
          <b-td class="text-center">
            {{ row.sales_total }}
          </b-td>
          <b-td class="text-center">
            % {{ row.sales_percent }}
          </b-td>
        </b-tr>
      </b-tbody>
      <b-tfoot>
        <b-tr>
          <b-th>Toplam</b-th>
          <template v-for="(total,tKey) in report.totals.data">
            <b-th
              :key="'int_' + tKey"
              class="text-center"
            >
              {{ total.interviews }}
            </b-th>
            <b-th
              :key="'sale_' + tKey"
              class="text-center"
            >
              {{ total.sales }}
            </b-th>
          </template>
          <b-th class="text-center">
            {{ report.totals.interviews_total }}
          </b-th>
          <b-th class="text-center">
            {{ report.totals.sales_total }}
          </b-th>
          <b-th class="text-center">
            % {{ report.totals.sales_percent }}
          </b-th>
        </b-tr>
      </b-tfoot>
    </b-table-simple>
  </div>
</template>
<script>
import {
  BTableSimple, BTbody, BTd, BTfoot, BTh, BThead, BTr,
} from 'bootstrap-vue'

export default {
  name: 'Sales',
  components: {
    BTableSimple,
    BTbody,
    BTd,
    BTfoot,
    BTh,
    BThead,
    BTr,
  },
  computed: {
    report() {
      return this.$store.getters['digitalReport/getReport']
    },
  },
}
</script>

<style scoped></style>
