<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-row>
            <b-col
              xs="12"
              md="4"
            >
              <b-form-group label="Marka">
                <v-select
                  v-model="id_com_brand"
                  label="title"
                  :options="brands"
                  placeholder="Seçiniz"
                  :close-on-select="false"
                  :reduce="brand => brand.id"
                  multiple
                />
              </b-form-group>
            </b-col>
            <b-col
              xs="12"
              md="4"
            >
              <b-form-group label="Temas Tipi">
                <v-select
                  v-model="id_com_meet"
                  label="title"
                  :options="meets"
                  placeholder="Seçiniz"
                  :close-on-select="false"
                  :reduce="meet => meet.id"
                  multiple
                />
              </b-form-group>
            </b-col>
            <b-col
              xs="12"
              md="4"
            >
              <b-form-group label="Yıl">
                <v-select
                  v-model="year"
                  label="title"
                  :options="years"
                  placeholder="Seçiniz"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
            >
              <b-form-group class="text-center">
                <b-button
                  variant="primary"
                  @click="getReport"
                >
                  <FeatherIcon icon="BarChartIcon" />
                  <span class="align-middle">Raporu Görüntüle</span>
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-card>
          <sales />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Sales from '@/views/Reports/Sales/DigitalReport/Sales.vue'

export default {
  name: 'DigitalReport',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    vSelect,
    Sales,
  },
  data() {
    return {
      id_com_brand: null,
      id_com_meet: null,
      year: 2023,
      years: [2023, 2022, 2021, 2020, 2019],
    }
  },
  computed: {
    brands() {
      return this.$store.getters['brands/getBrands']
    },
    meets() {
      return this.$store.getters['meets/getMeets']
    },
  },
  created() {
    this.getBrands()
    this.getMeets()
  },
  methods: {
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        select: ['com_brand.id AS id', 'com_brand.name AS title'],
        where: { salestatus: 1 },
      })
    },
    getMeets() {
      this.$store.dispatch('meets/meetsList', {
        select: ['com_meet.id AS id', 'com_meet.title AS title'],
        where: {
          id_com_meet_group: 1,
        },
      })
    },
    getReport() {
      this.$store.dispatch('digitalReport/report', {
        year: this.year,
        id_com_brand: this.id_com_brand,
        id_com_meet: this.id_com_meet,
      })
    },
  },
}
</script>
